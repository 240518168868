
import {computed, defineComponent, onMounted, onUpdated, ref} from 'vue'
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import {PROCESS_TYPE} from "@/core/config/Constant";
import Entities from "@/components/base/common/Entities.vue";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import Avatar from "@/components/base/common/Avatar.vue";
import store from "@/store";
import {CompanySelect, LoadFilterObjects, TagSelect} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import ClaimLink from "@/views/claim/ClaimLink.vue";
import InvoiceLink from "@/views/invoice/InvoiceLink.vue";
import CaseLink from "@/views/case/CaseLink.vue";
import ProcessStatus from "@/views/process/ProcessStatus.vue";
import BaseSelect from "@/components/base/select/BaseSelect.vue";

export default defineComponent({
  name: "ProcessSearch",
  components: {
    BaseSelect,
    ProcessStatus,
    CaseLink,
    InvoiceLink,
    ClaimLink,
    Avatar,
    DateTimeFormat,
    KtDatatable,
    Entities
  },
  props: {
    type: {required: true, type: String}
  },
  setup(props) {
    const objectFilter = ref({type: props.type, clientId: '', completed: false, tagId: []})
    const page = computed(() => store.state.ProcessModule.allPage);
    const header = [
      {
        name: 'Created',
        key: 'created',
        sortable: false
      },
      {
        name: 'Related',
        key: 'related',
        sortable: false
      },
      {
        name: 'Name',
        key: 'name',
        sortable: false
      },
      {
        name: 'Assigned',
        key: 'assigned',
        sortable: false
      },
      {
        name: 'Status',
        key: 'status',
        sortable: false
      },
    ]
    onMounted(() => {
      buildHeader(props.type)
    })
    onUpdated(() => {
      buildHeader(props.type);
    })
    const buildHeader = (type) => {
      const title = PROCESS_TYPE[type].names
      setCurrentPageBreadcrumbsWithParams(title,
        [
          {link: false, router: '', text: title}
        ]
      )
    }
    return {

      page,
      PROCESS_TYPE,
      objectFilter, header,
      ...CompanySelect(),
      ...TagSelect('PROCESS', props.type),
      ...LoadFilterObjects(Actions.LOAD_ALL_PROCESS, objectFilter.value, ['assigned', 'caze', 'claim', 'invoice']),
    }
  },
  watch: {
    type(cb) {
      this.objectFilter.type = cb;
      this.updateFilterObject(this.objectFilter);
    }
  },
  methods: {
    discardEvent() {
      this.updateFilterObject(this.objectFilter);
    },
    searchEvent() {
      this.updateFilterObject(this.objectFilter)
    },
  }
})
