
import {defineComponent, ref, watch} from 'vue'

export default defineComponent({
  name: "ProcessStatus",
  props: {
    status: {},
  },
  setup(props) {
    const data = ref({name: '', clazz: ''});

    const build = (status) => {
      switch (status) {
        case 'ACTIVE':
          data.value = {name: 'Active', clazz: 'badge-primary'}
          break
        case 'PENDING' :
          data.value = {name: 'Pending', clazz: 'badge-secondary'}
          break
        case 'COMPLETE':
          data.value = {name: 'Completed', clazz: 'badge-info'}
          break
      }
    }
    build(props.status)

    watch(() => props.status, cb => {
      build(cb);
    })

    return {
      data
    }
  }

})
